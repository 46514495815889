:root {
  --background-color: linear-gradient(312.98deg, #ebf3f5 0%, #f7f7f4 100%);
  --table-background: linear-gradient(90deg, #009dde 0%, #234678 100%);

  --main-color: #ce4b4e;
  --main-color100: #f2e7fe;
  --main-Color-500: #6200ee;
  --background-color: #181c24;
  --big-medium-size: 25px;
  --white: #fff;
  --small1-font-size: 15px;
  --borderColor: #9747ff;
  --font-weight-medium: 600;
}

::before,
::after {
  box-sizing: border-box;
}

.input_border {
  background: var(--Background-Colors-Achromatic-White, #fff);
  border-radius: 0px !important;
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
  -ms-border-radius: 0px !important;
  -o-border-radius: 0px !important;
}

button:active {
  transform: scale(0.98);
  box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
  transition: all 0.1s;
}
.button:active {
  transform: scale(0.98);
  box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
  transition: all 0.1s;
}

INPUT[type="checkbox"] {
  background-color: #ddd;
  border-radius: 5px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 24px !important;
  height: 23px;
  cursor: pointer;
  background: var(--white);
  border: 2px solid #c5c5c5;
}

input[type="checkbox"]:checked:before {
  color: var(--main-color) !important;
}

input[type="checkbox"] + label:before,
input[type="radio"] + label:before {
  border-radius: 2px;
  background-color: var(--main-color);
}

INPUT[type="checkbox"]:checked {
  background-color: var(--main-color);
  background: var(--main-color)
    url("data:image/gif;base64,R0lGODlhCwAKAIABAP////3cnSH5BAEKAAEALAAAAAALAAoAAAIUjH+AC73WHIsw0UCjglraO20PNhYAOw==")
    6px 4px no-repeat;
}
input[type="radio"] {
  appearance: none;
  background-color: var(--white);
  margin: 0;
  font: inherit;
  color: var(--main-color);
  width: 1.2em;
  height: 1.2em;
  border: 0.1em solid #bcb8b8;
  border-radius: 50%;
  transform: translateY(-0.075em);

  display: grid;
  place-content: center;
}

input[type="radio"]::before {
  content: "";
  width: 0.8em;
  height: 0.8em;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--main-color);
}

input[type="radio"]:checked::before {
  transform: scale(1);
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
}

/*
============================
Checkbox css section  End
==========================
*/

/*
============================
Model Css Code
==========================
*/

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}
/*
============================
Model Css Code
==========================
*/

.react-datepicker__input-container,
.react-datepicker-wrapper {
  width: 100% !important;
  /* background-color: #fff; */
}

.chat_text_ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
